import React from 'react';
import PropTypes from 'prop-types';
import Navbar from 'containers/Navbar';
import { Drawer } from 'components/Drawer';
import { Notifications } from 'modules/notification';
import {
  hexToRgb,
  //variables
  drawerWidth,
  drawerMiniWidth,
  transition,
  container,
  containerFluid,
  boxShadow,
  card,
  defaultFont,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  blackColor,
  whiteColor,
  twitterColor,
  facebookColor,
  googleColor,
  linkedinColor,
  pinterestColor,
  youtubeColor,
  tumblrColor,
  behanceColor,
  dribbbleColor,
  redditColor,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  cardActions,
  cardHeader,
  defaultBoxShadow,
  tooltip,
  title,
  cardTitle,
  cardSubtitle,
  cardLink
} from 'assets/jss/material-dashboard-pro-react.jsx';

export const CoreLayout = ({ children, classes, setOpen, open, setDrawerItems, drawerItems }) => (
  <div className={classes.container}>
    <Navbar setOpen={setOpen} open={open} />
    <Drawer open={open} drawerItems={drawerItems} setOpen={setOpen} />
    <div className={classes.coreContainer}>
    <div className={classes.children}>{children}</div>
    <Notifications />
    </div>
    </div>
);

CoreLayout.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
  children: PropTypes.element.isRequired
};

export default CoreLayout;
