const drawerWidth = 240;
export default theme => ({
  drawerBackground: {
    background: '#000000',
    position: 'fixed',
    width: '100%',
    height: '100%',
    transition: 'all .25s ease-in-out',
    opacity: 0.5
  },

  menuButton: {
    marginLeft: 12,
    marginRight: 20
  },
  hide: {
    display: 'none'
  },
  listItem: {
    fontWeight: 700
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  hideDrawer: {
    display: 'none'
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
});
