export const env = 'local';

export const firebase = {
  apiKey: 'AIzaSyCRGvn1NbEVWgH5KdNQcD-8MtcaCKb9gVU',
  authDomain: 'wills-platform.firebaseapp.com',
  databaseURL: 'https://wills-platform.firebaseio.com',
  projectId: 'wills-platform',
  storageBucket: 'wills-platform.appspot.com',
  messagingSenderId: '117655512342'
};

export const cloudFunctionBaseUrl = {
  test: "https://us-central1-mytribe-staging-55aef.cloudfunctions.net",
  production: "https://us-central1-wills-platform.cloudfunctions.net"
};

export const misc = { emailverificationURL: 'https://app.my-tribe.com.au' };
export const paypal = {
  sandboxAccount: 'Sandbox account email here',
  sandboxClient: 'Sandbox client id here',
  productionClient: 'Production client id here'
};
export const reduxFirebase = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableLogging: false,
  enableClaims: true
};
export const stripe = {
  test: {
    apiKey:
      'pk_test_51H5HiUKyVt0IbXPx1aqlmB6dKQP0u6hV4acwdz31iS13BgKppS2Cjqblj58zOUE4xm7n9iabopJshIblBusbxfzZ009kB2KKtA'
  },
  live: {
    apiKey:
      'pk_live_51H5HiUKyVt0IbXPxU8rn922HLT7Glugf1mKmgxInD1AAW8dlfVxGW1fYuBTYmpdWdE5vWJDjG46ZbCq4iQWmVjcj00H5liitOX'
  }
};

export default { env, firebase, reduxFirebase, paypal, stripe, cloudFunctionBaseUrl };
