import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ThemeSettings from 'theme';
import { stripe } from '../../config.js';

const theme = createMuiTheme(ThemeSettings);
const stripePromise = loadStripe(stripe.live.apiKey);

const App = ({ routes, store }) => (
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <Router>{routes}</Router>
      </Elements>
    </Provider>
  </MuiThemeProvider>
);

App.propTypes = {
  routes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default App;
