export default {
  palette: {
    primary: {
      main: '#2196f3',
      dark: '#2228B3'
    },
    secondary: {
      main: '#FF8200'
    },
    blueButton: {
      main: '#2228B3'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }
};
