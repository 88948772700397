import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';
import CreateIcon from '@material-ui/icons/Create';
import EventIcon from '@material-ui/icons/Event';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import {
  LAWYER_DASHBOARD_PATH,
  VIEW_WILL_PATH,
  LD_CLIENT_BOOKINGS_PATH,
  CLIENT_DASHBOARD_PATH,
  ADMIN_DASHBOARD_PATH,
  AD_BOOKINGS_PATH,
  AD_APPROVE_LAWYER_PATH,
  UPLOAD_DOCUMENTS_PATH,
  CLIENT_BOOKING_PATH,
  VIEW_DOCUMENTS_PATH,
} from 'constants/paths.js';

const lawyerListItems = [
  { text: 'Home', path: LAWYER_DASHBOARD_PATH, icon: HomeIcon },
  { text: 'Bookings', path: LD_CLIENT_BOOKINGS_PATH, icon: CreateIcon },
  { text: 'Upload Will', path: UPLOAD_DOCUMENTS_PATH, icon: InboxIcon },
  { text: 'View Templates', path: VIEW_DOCUMENTS_PATH, icon: CreateIcon }
];
const clientListItems = [
  { text: 'Home', path: CLIENT_DASHBOARD_PATH, icon: HomeIcon },
  { text: 'Make Booking', path: CLIENT_BOOKING_PATH, icon: CreateIcon },
  { text: 'View Will', path: VIEW_WILL_PATH, icon: InboxIcon }
];
const adminListItems = [
  { text: 'Home', path: ADMIN_DASHBOARD_PATH, icon: HomeIcon },
  { text: 'Bookings', path: AD_BOOKINGS_PATH, icon: CreateIcon },
  { text: 'Lawyers', path: AD_APPROVE_LAWYER_PATH, icon: InboxIcon }
]

export const PersistentDrawer = ({
  classes,
  open,
  auth,
  authExists,
  setOpen,
  drawerItems = [],
  profile
}) => {
  if (authExists && auth.emailVerified) {
    var userListItems = [];
    switch(profile.role) {
      case 'lawyer':
        userListItems = lawyerListItems;
        break;
      case 'admin':
          userListItems = adminListItems;
          break;
      default:
        userListItems = clientListItems;
        break;
    }

    return (
      <>
        <div
          className={open ? classes.drawerBackground : ''}
          onClick={() => setOpen(!open)}
        />
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='left'
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader} />
          <Divider />
          <List>
            {userListItems.map((obj, index) => {
              const ItemIcon = obj.icon === '' ? MailIcon : obj.icon;
              return (
                <ListItem button key={obj.text} component={Link} to={obj.path}>
                  <ListItemIcon>
                    {' '}
                    <ItemIcon />{' '}
                  </ListItemIcon>
                  <ListItemText primary={obj.text} />
                </ListItem>
              );
            })}
          </List>
          <Divider />
          <List>
            {drawerItems.map((item, index) => (
              <ListItem button key={item.path} component={Link} to={item.path}>
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </>
    );
  } else {
    return <div className={classes.hideDrawer} />;
  }
};

PersistentDrawer.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
  open: PropTypes.bool.isRequired // from enhancer (setPropTypes)
};
export default PersistentDrawer;
