import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import createHistory from 'history/createBrowserHistory';
import LoadingSpinner from 'components/LoadingSpinner';
import { LIST_PATH, EMAIL_VERIFICATION } from 'constants/paths';

const locationHelper = locationHelperBuilder({});
const history = createHistory();

const AUTHED_REDIRECT = 'AUTHED_REDIRECT';
const UNAUTHED_REDIRECT = 'UNAUTHED_REDIRECT';
const VERIFIED_REDIRECT = 'VERIFIED_REDIRECT';
const UNVERIFIED_REDIRECT = 'UNVERIFIED_REDIRECT';
const NO_PERMISSION_REDIRECT = 'NO_PERMISSION_REDIRECT';

// Should this have return can; instead of return true;?
// Currently seems to always return true...
const checkRoleHasPermission = (permissions, role) => {
  var can = true;
  permissions.forEach(e => {
    if (!role[e]) can = false;
  });
  return true;
};

export const UserIsAdmin = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsAdmin',
  allowRedirectBack: false,
  authenticatedSelector: ({ firebase: { profile } }) => !profile.isEmpty && profile.role === 'admin',
  authenticatingSelector: ({ firebase: { isInitializing, profile } }) => isInitializing || !profile.isLoaded,
  redirectPath: '/',
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.replace(newLoc);
    dispatch({
      type: NO_PERMISSION_REDIRECT,
      payload: { message: `User is not an admin.` }
    });
  }
});

export const UserIsLawyer = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsLawyer',
  allowRedirectBack: false,
  authenticatedSelector: ({ firebase: { profile } }) => !profile.isEmpty && (profile.role === 'lawyer' || profile.role === 'admin'),
  authenticatingSelector: ({ firebase: { isInitializing, profile } }) => isInitializing || !profile.isLoaded,
  redirectPath: '/',
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.replace(newLoc);
    dispatch({
      type: NO_PERMISSION_REDIRECT,
      payload: { message: `User is not an lawyer.` }
    });
  }
});

export const UserIsClient = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsClient',
  allowRedirectBack: false,
  authenticatedSelector: ({ firebase: { profile } }) => !profile.isEmpty && profile.role === 'client',
  authenticatingSelector: ({ firebase: { isInitializing, profile } }) => isInitializing || !profile.isLoaded,
  redirectPath: '/',
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.replace(newLoc);
    dispatch({
      type: NO_PERMISSION_REDIRECT,
      payload: { message: `User is not an client.` }
    });
  }
});

export const UserIsReferrer = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsReferrer',
  allowRedirectBack: false,
  authenticatedSelector: ({ firebase: { profile } }) => !profile.isEmpty && profile.role === 'referrer',
  authenticatingSelector: ({ firebase: { isInitializing, profile } }) => isInitializing || !profile.isLoaded,
  redirectPath: '/',
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.replace(newLoc);
    dispatch({
      type: NO_PERMISSION_REDIRECT,
      payload: { message: `User is not an referrer.` }
    });
  }
});

export const UserIsReferrerOrLawyer = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsReferrerOrLawyer',
  allowRedirectBack: false,
  authenticatedSelector: ({ firebase: { profile } }) => !profile.isEmpty && (profile.role === 'referrer' || profile.role === 'lawyer'),
  authenticatingSelector: ({ firebase: { isInitializing, profile } }) => isInitializing || !profile.isLoaded,
  redirectPath: '/',
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.replace(newLoc);
    dispatch({
      type: NO_PERMISSION_REDIRECT,
      payload: { message: `User is not an referrer or a lawyer.` }
    });
  }
});

/**
 * Higher Order Component that redirects to `/login` instead
 * rendering if user is not authenticated (default of redux-auth-wrapper).
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent
 */
export const UserIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsAuthenticated',
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth } }) => !auth.isEmpty && !!auth.uid,
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) => !auth.isLoaded || isInitializing,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc);
    dispatch({
      type: UNAUTHED_REDIRECT,
      payload: { message: 'User is not authenticated.' }
    });
  }
});

export const UserHasPermission = permissions =>
  connectedRouterRedirect({
    wrapperDisplayName: 'UserHasPermission',
    allowRedirectBack: false,
    authenticatedSelector: ({
      firebase: { auth, profile },
      firestore: {
        data: { role }
      }
    }) => !auth.isEmpty && !!auth.uid && !profile.isEmpty && role && checkRoleHasPermission(permissions, role),
    authenticatingSelector: ({
      firebase: { auth, isInitializing, profile },
      firestore: {
        data: { role }
      }
    }) => !role || !auth.isLoaded || isInitializing || !profile.isLoaded,
    redirectPath: '/',
    redirectAction: newLoc => dispatch => {
      // Use push, replace, and go to navigate around.
      history.replace(newLoc);
      dispatch({
        type: NO_PERMISSION_REDIRECT,
        payload: { message: `User has no ${permissions} permission.` }
      });
    }
  });

/**
 * Higher Order Component that redirects to listings page or most
 * recent route instead rendering if user is not authenticated. This is useful
 * routes that should not be displayed if a user is logged in, such as the
 * login route.
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent
 */
export const UserIsNotAuthenticated = connectedRouterRedirect({
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  allowRedirectBack: false,
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth } }) => auth.isEmpty,
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) => !auth.isLoaded || isInitializing,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || EMAIL_VERIFICATION,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc);
    dispatch({
      type: AUTHED_REDIRECT,
      payload: { message: 'User is not authenticated.' }
    });
  }
});

/**
 * Higher Order Component that redirects to `/login` instead
 * rendering if user is not authenticated (default of redux-auth-wrapper).
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent
 */
export const UserIsVerified = connectedRouterRedirect({
  redirectPath: '/email-verification',
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsVerified',
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth } }) => !auth.isEmpty && auth.emailVerified,
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) => !auth.isLoaded || isInitializing,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc);
    dispatch({
      type: UNVERIFIED_REDIRECT,
      payload: { message: 'User is not verified.' }
    });
  }
});

/**
 * Higher Order Component that redirects to verification page or most
 * recent route instead rendering if user is not verified. This is useful
 * routes that should not be displayed if a user is logged in, such as the
 * login route.
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent
 */
export const UserIsNotVerified = connectedRouterRedirect({
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsNotVerified',
  allowRedirectBack: false,
  // Want to redirect the user when they are done loading and verified
  authenticatedSelector: ({ firebase: { auth } }) => auth.isEmpty || !auth.emailVerified,
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) => !auth.isLoaded || isInitializing,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || LIST_PATH,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc);
    dispatch({
      type: VERIFIED_REDIRECT,
      payload: { message: 'User is not verified.' }
    });
  }
});
