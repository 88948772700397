import { compose, flattenProp, setPropTypes, withHandlers, withProps, withState, withStateHandlers } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import styles from './PersistentDrawer.styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFirebase, isEmpty, isLoaded } from 'react-redux-firebase';
import { UserIsVerified } from 'utils/router';
import { spinnerWhileLoading } from 'utils/components';
export default compose(

  setPropTypes({
    open: PropTypes.bool.isRequired, // drawer open or not
    setOpen: PropTypes.func.isRequired // change open state
  }),
  connect(({ firebase: { profile, auth } }) => ({
    profile,
    auth
  })),
  // Add styles as classes prop
  spinnerWhileLoading(['profile']),


  withProps(({ auth, profile }) => ({
    authExists: isLoaded(auth) && !isEmpty(auth)
  })),
  // Flatten profile so that avatarUrl and displayName are props
  flattenProp('profile'),
  // Add styles as classes prop
  withStyles(styles)
);
