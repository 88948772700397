import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { LOGIN_PATH, SIGNUP_PATH, LAWYER_SIGNUP_PATH, REFERRER_SIGNUP_PATH, REFERRER_SIGNUP_PATH_ROOT} from 'constants/paths';

const buttonStyle = {
  color: 'white',
  textDecoration: 'none',
  alignSelf: 'center',
  backgroundColor: '#00acc1',
  margin: '5px'
};

export const LoginMenu = () => (
  <div>
    {/* <Button style={buttonStyle} component={Link} to={SIGNUP_PATH}>
      Sign Up
    </Button>
    <Button style={buttonStyle} component={Link} to={LAWYER_SIGNUP_PATH}>
      Lawyer Sign Up
    </Button>
    <Button style={buttonStyle} component={Link} to={REFERRER_SIGNUP_PATH_ROOT}>
      Referrer Sign Up
    </Button>
    <Button style={buttonStyle} component={Link} to={LOGIN_PATH}>
      Login
    </Button> */}
  </div>
);

export default LoginMenu;
