import { connect } from 'react-redux';
import { withHandlers, compose, withProps, flattenProp, withStateHandlers, withState, setPropTypes } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { withFirebase, isEmpty, isLoaded } from 'react-redux-firebase';
import { ACCOUNT_PATH, LOGIN_PATH } from 'constants/paths';
import styles from './Navbar.styles';
import PropTypes from 'prop-types';

export default compose(
  // Map redux state to props
  connect(({ firebase: { auth, profile } }) => ({
    auth,
    profile
  })),
  // State handlers as props
  withStateHandlers(
    ({ accountMenuOpenInitially = false }) => ({
      accountMenuOpen: accountMenuOpenInitially,
      anchorEl: null
    }),
    {
      closeAccountMenu: ({ accountMenuOpen }) => () => ({
        anchorEl: null
      }),
      handleMenu: () => event => ({
        anchorEl: event.target
      })
    }
  ),
  // Add props.router (used in handlers)
  withRouter,
  // Add props.firebase (used in handlers)
  withFirebase,

  setPropTypes({
    open: PropTypes.bool.isRequired, // drawer open or not
    setOpen: PropTypes.func.isRequired // change open state
  }),
  // Handlers as props
  withHandlers({
    handleLogout: props => () => {
      props.firebase.logout();
      props.history.push(LOGIN_PATH);
      props.closeAccountMenu();
    },
    goToAccount: props => () => {
      props.history.push(ACCOUNT_PATH);
      props.closeAccountMenu();
    },
    handleDrawerOpen: props => () => {
      props.setOpen(!props.open);
    }
  }),
  // Add custom props
  withProps(({ auth, profile }) => ({
    authExists: isLoaded(auth) && !isEmpty(auth)
  })),
  // Flatten profile so that avatarUrl and displayName are props
  flattenProp('profile'),
  // Add styles as classes prop
  withStyles(styles)
);
