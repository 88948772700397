import { compose, flattenProp, withHandlers, withProps, withState, withStateHandlers } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import styles from './CoreLayout.styles';
import { UserHasSubscription } from '../../utils/router';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { spinnerWhileLoading } from "../../utils/components";

export default compose(
  // Map auth uid from state to props
  withFirebase,
  connect(({ firebase: { profile } }) => ({
    profile,
    avatarUrl: profile.avatarUrl
  })),
  spinnerWhileLoading(['profile']),

  withState('open', 'setOpen', false),
  withState('drawerItems', 'setDrawerItems', []),
  // Add styles as classes prop
  withStyles(styles)
);
