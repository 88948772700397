/* eslint-disable */
import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  ACCOUNT_PATH,
  LIST_PATH,
  LOGIN_PATH,
  SIGNUP_PATH,
  CLIENT_BOOKING_PATH,
  CLIENT_DASHBOARD_PATH,
  LAWYER_DASHBOARD_PATH,
  REFERRER_DASHBOARD_PATH,
  LD_CLIENT_BOOKINGS_PATH,
  UPLOAD_WILL_PATH,
  VIEW_WILL_PATH,
  ADMIN_DASHBOARD_PATH,
  AD_APPROVE_LAWYER_PATH
} from "constants/paths";

export const Home = ({ classes, firebase, profile, history, userData }) => {
  useEffect(() => {
    if (profile && profile.role === 'client') {
        history.push(CLIENT_DASHBOARD_PATH);
    }
    if (profile && profile.role === 'lawyer') history.push(LAWYER_DASHBOARD_PATH);
    if (profile && profile.role === 'referrer') history.push(REFERRER_DASHBOARD_PATH);
    if (profile && profile.role === 'admin') history.push(ADMIN_DASHBOARD_PATH);

  }, [profile]);
  return <div className={classes.root}>{profile && profile.role !== 'client'}</div>;
};

Home.proptypes = {
  classes: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired

};

export default Home;
