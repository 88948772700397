export default theme => ({
  flex: {
    flexGrow: 1,
  },
  appbarTop: {
    zIndex: 1300,
    color: '#000',
    //background: 'linear-gradient( 90deg, #fff 40%,#ff7900 80%,#ff7900 100%)'
    background: '#fff'
  },
  navbarAvatar: {
    width: 30,
    height: 30,
    maxWidth: 30,
    position: 'absolute',
    borderRadius: '50%',
    border: 'none',
    objectFit: 'cover'
  },
  logo: {
    marginTop: 5,
    height: 50,
    maxWidth: '50%'
  }
});
