export const LIST_PATH = '/';
export const EMAIL_VERIFICATION = '/email-verification';
export const LAWYER_SIGNUP_PATH = '/lawyer-signup';
export const ACCOUNT_PATH = '/account';
export const LOGIN_PATH = '/login';
export const ROOT_PATH = '';
export const SIGNUP_PATH = '/signup';
export const REFERRER_SIGNUP_PATH = '/referrer-signup/:organisation?';
export const REFERRER_SIGNUP_PATH_ROOT = '/referrer-signup';
export const REFERRER_DETAIL_INFO_PATH = '/referrer-detail-info';
export const CLIENT_BOOKING_PATH = '/client-booking';
export const CLIENTS_PATH = '/clients';
export const REFERRERS_PATH = '/referrers';
export const REFERRER_BOOKING_PATH = '/referrer-booking';
export const ADMIN_DASHBOARD_PATH = '/admin-dashboard';
export const CLIENT_DASHBOARD_PATH = '/client-dashboard';
export const LAWYER_DASHBOARD_PATH = '/lawyer-dashboard';
export const REFERRER_DASHBOARD_PATH = '/referrer-dashboard';
export const LD_CLIENT_BOOKINGS_PATH = '/client-bookings';
export const INVITE_REFERRERS_PATH = '/referrer-invite';
export const REFERRER_PROGRAM_PATH = '/referrer-program';
export const UPLOAD_WILL_PATH = '/upload-will';
export const UPLOAD_DOCUMENTS_PATH = '/upload-documents';
export const VIEW_WILL_PATH = '/view-will';
export const AD_BOOKINGS_PATH = '/admin-bookings';
export const AD_APPROVE_LAWYER_PATH = '/approve-lawyer';
export const VIEW_DOCUMENTS_PATH = '/view-documents';
export const CLIENT_CONSULTATION_PATH = '/consultation';
export const RESET_PASSWORD_PATH = '/reset';
