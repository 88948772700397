import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CoreLayout from '../layouts/CoreLayout';
import HomeRoute from './Home';
import LoginRoute from './Login';
import SignupRoute from './Signup';
import EmailVerificationRoute from './EmailVerification';
import LawyerSignup from './LawyerSignup';
import ReferrerSignup from './ReferrerSignup';
import AccountRoute from './Account';
import ClientDashboardRoute from './ClientDashboard'
import LawyerDashboardRoute from './LawyerDashboard'
import ReferrerDashboardRoute from './ReferrerDashboard'
import NotFoundRoute from './NotFound';
import ClientForm from './ClientBooking';
import ReferrerForm from './ReferrerBooking';
import ClientBookings from './ClientBookings';
import Clients from './Clients';
import Referrers from './Referrers';
import ReferrerInvitePage from './ReferrerInvite';
import ReferrerProgramPage from './ReferrerProgram';
import UploadWill from './UploadWill';
import UploadDocuments from './UploadDocuments';
import ViewWill from './ViewWill';
import AdminDashboardRoute from './AdminDashboard'
import AdminBookings from './AdminBookings'
import ApproveLawyer from './ApproveLawyer';
import ViewLawyerDocuments from './ViewLawyerDocuments';
import Consultation from './Consult';
import ResetPassword from './ResetPassword';

export default function createRoutes(store) {
  return (
      <Switch>
        <CoreLayout>
        {/* Build Route components from routeSettings */
        [
          LoginRoute
          /* Add More Routes Here */
        ].map((settings, index) => (
          <Route key={`Route-${index}`} {...settings} />
        ))}
      <Switch>
        {/* Build Route components from routeSettings */
        [
          AccountRoute,
          ApproveLawyer,
          ClientDashboardRoute,
          LawyerDashboardRoute,
          AdminDashboardRoute,
          AdminBookings,
          ClientBookings,
          ReferrerDashboardRoute,
          SignupRoute,
          EmailVerificationRoute,
          LawyerSignup,
          ReferrerSignup,
          ClientForm,
          Clients,
          Referrers,
          ReferrerForm,
          ReferrerInvitePage,
          ReferrerProgramPage,
          UploadWill,
          ViewWill,
          ViewLawyerDocuments,
          UploadDocuments,
          Consultation,
          ResetPassword,
          HomeRoute
          /* Add More Routes Here */
        ].map((settings, index) => (
          <Route key={`Route-${index}`} {...settings} />
        ))}
        <Route component={NotFoundRoute.component} />
      </Switch>
    </CoreLayout>
    </Switch>
  );
}
