import { withStyles } from '@material-ui/core/styles';

import { LOGIN_PATH, SIGNUP_PATH, LAWYER_SIGNUP_PATH, REFERRER_SIGNUP_PATH, ADMIN_DASHBOARD_PATH} from 'constants/paths';

/*eslint no-unused-vars: 0*/
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withHandlers, withStateHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withNotifications } from 'modules/notification';
import { spinnerWhileLoading } from 'utils/components';
import { UserIsAuthenticated, UserIsClientHome, UserIsAdmin, UserIsVerified, UserIsClient, UserIsReferrer, UserIsLawyer, UserHasPermission } from 'utils/router';
import { firestoreConnect, withFirebase } from 'react-redux-firebase';

import styles from './HomePage.styles';

export default compose(

  UserIsAuthenticated,
  withFirebase,
  withNotifications,
  connect(({ firebase: { profile } }) => ({
    profile,
    avatarUrl: profile.avatarUrl
  })),
  spinnerWhileLoading(['profile']),
  withStyles(styles)
);
