import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { reducer as form } from 'redux-form';
import { reducer as notifications } from 'modules/notification';
import locationReducer from './location';

export function makeRootReducer(asyncReducers) {
  return combineReducers({
    // Add sync reducers here
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    form,
    notifications,
    location: locationReducer,
    ...asyncReducers
  });
}

export function injectReducer(store, { key, reducer }) {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
}

export default makeRootReducer;
